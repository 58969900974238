import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`“Sell in May and Go Away” is an often-cited investment idea, aiming to benefit from seasonal differences in stock market returns. And, given that the past few days have been a bumpy ride, one might ask if there is some truth to that. Let’s find out.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d91c87a0f5dbc09fe63beb3ce94dd01a/39c09/sp500.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABPUlEQVQoz51SaU/DMAzt//99CImJjmoHQ2hrl7aJ7Rx+KL128AE2S0927JeXOE6hMQIpAaqDj+IRRaAh4Pvwhaossf2osN9sUZVrUG+RfJh4t1BVFMacUb2tUL68YrN6h5gO0o6g5gxbN+hP9YhjDTbtUr8GZxChEHL43O+xXlfY7naI+aZPWN5lrUXhvb8tqD6MLKcpgZlRhBAuRzxpYRiDgnLLHRFs9ODkYURwcoyj4yHOuQwXPVoRUPQQHXPZ90EGbsOCmhjWORQ9E2qWRYTSb/FMbqd1wzyItX7cQ5M4RxkF49zyP02CoqMEy3dvpArKgvNQHhlATAkpx1f5NA/FP3jDP78NuQ6BDLwz8LO/iyO3F84NzgtPnJlaFgZTDyE7IMfsegi7wee1aU6wvYGwHWsTj2y35DLyF/wBhAir6HE4MfQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sp500",
            "title": "sp500",
            "src": "/static/d91c87a0f5dbc09fe63beb3ce94dd01a/39c09/sp500.png",
            "srcSet": ["/static/d91c87a0f5dbc09fe63beb3ce94dd01a/5a46d/sp500.png 300w", "/static/d91c87a0f5dbc09fe63beb3ce94dd01a/0a47e/sp500.png 600w", "/static/d91c87a0f5dbc09fe63beb3ce94dd01a/39c09/sp500.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When charting average monthly returns from 1970 to today (53 years), it seems that this simple saying has some merit: we can see that the five months from May to September indeed underperform. To create some more context, we calculated the range one standard deviation above and below the average monthly returns. The monthly averages are well within these boundaries, telling us that the statistical significance of this deviation is rather low and likely not suitable for trading.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/48ab72f894c06a7de4c66239a591218b/39c09/sim-equity.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACfklEQVQoz2WTy2sTURTGr4qgbt24c+nf4H/gXkF8FBWtStoq+OqqKCq4UEQFF4IP7BPrQgURfEMVLGhrkzRJH9a2pu1Mk3SSycwkc2fmzk9mYktbD3z3nHPv4eMezndExXLILyxiWxbS83BdFyll7D3Pw7IsdF2nUjHjvF53cV2JW5ex94M6dmjgeCYqUIiqU6NQMpCuy3oLw3AlVlFOGJ8QAD4ODsnFMr1DJT6OGqAkwpNyLUEYxj5QIarBgo9LmTKat8SXaYMP0xpdwwvsvjHF9rPjiIMZWjpnAA8RtaaUQv0j8YNw1R89POrkiiaHH86zq2OCbYkcWxM5NjbnEE2jbGjOII5kuNg3g28bDcL1VlMuXSN5rr3Ns+feNDvOTyCOjiKOZ9h0Khtj8+ksWxJZtiayiGMZLj37A6reILSkJLXg8DJd4vK73+zsSCH2JxF7k4h9ScSBFKIphTicbuBQqoEobkrHdW3df+LPiEXT4nWuwM3Pc7T0zXHyqcaJJzoX+wu0Py/S1qvR2qNzrrfIhf5FWrp12p8XYiS6Nc726bR2F3n1w4iHJVLFOe4MjfLg+yy9MyM8nhpiwMxyP5Xm1vAIKXuWYWeSR/mv3H5fYKRY4MrHSa5/niQTjDNQmuTu2CCfpvIQBAhTmgxqGj2/MpR9mzFLx1F1XkxNMzCnEYQB9cAjZ2l0jmcwQoMzb9Jc/fYzFtO8ZTOg50kuzWKbVUQgfXw8HGrrVfjfsH77eUIUybKGRK55U9LDrJiISP22beM4NSzbwqnV4u2wbSdGFJumSbVaxXGcuNawjJW65btouEHU8rKg10OFaiWOVjHS6sp7JPrVtfEGNOwvjIxMInpRC6oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sim equity",
            "title": "sim equity",
            "src": "/static/48ab72f894c06a7de4c66239a591218b/39c09/sim-equity.png",
            "srcSet": ["/static/48ab72f894c06a7de4c66239a591218b/5a46d/sim-equity.png 300w", "/static/48ab72f894c06a7de4c66239a591218b/0a47e/sim-equity.png 600w", "/static/48ab72f894c06a7de4c66239a591218b/39c09/sim-equity.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Nonetheless, we ran a backtest of this trading idea. We can see that, on absolute metrics, the strategy certainly underperforms. However, we can see that in 1974, 2002, 2009, 2011, and 2022, the drawdowns were lower. Maybe there is some value there?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cfe722fc9a62366072b260e97cd6b0af/39c09/sim-rolling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACK0lEQVQoz4WSy2tTQRTG718muHfv1oU7d278B8SNSxEUFEEEcdEKLq2iBXHT4qNPE/NokpI0aZK5j5k7jztzf3Inta1Q8MDHnDln5jtzzjdJXQcaq+uayntCHVgKwdHRgF6/x3A4YjKZxHU0GnE0GDA9meJ9iOf9GUKoI08iCkWnPyIVKaEKSFOyLAXBaEqpkIUklwVKSaSUcZ8VBd5bgvNY43CVJZc5zjqSU1kwlQvWd8cEKlrFjHe9Ie+PB2S1jFVbesJ6exR9jwMqfixm5F7H2EALxrlAphlJ+yTn/qcud9Z/83K/zZNvHW6+2ufG8z3ubezxbHvA7dcdrj/eZW1nxsOvLd4ejrn2dItbbw54tNXm7sfvfD48xRQpSXAVm52UtYNjuvOS3WlGXyg2WoLN3pLuqaZz4vh5rHix12KSVjz4MORLN2Mwd2yPcnbGil/TGZkQJJVzsRGD4X9mWbXYzzNc7f7JBe+wzQyds1GhaeZZSt/oHRUPV4Ca1UqgPvMbNH5D1qidpNIwyyu0CxHzwuN8ff6VzsElv/4315jWmjRNSbSxSOPxYZVMS8+8qMi1v9TQ34v1FYO4IBRL0bTsrpyXNDVCNa9u2rqIRdimcKAwq3ycrzWIRhRjDNZaGuLL8JVDaUtRWhaFZZFrloWJsWWuyZWl1JZMGua5QWQy8iQhBMqyjE9WStEUUFLFmNEaXSqESBFZgdZlRFkqrDHxjDWaQipctRrRH3vlkrX4nwxlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sim rolling",
            "title": "sim rolling",
            "src": "/static/cfe722fc9a62366072b260e97cd6b0af/39c09/sim-rolling.png",
            "srcSet": ["/static/cfe722fc9a62366072b260e97cd6b0af/5a46d/sim-rolling.png 300w", "/static/cfe722fc9a62366072b260e97cd6b0af/0a47e/sim-rolling.png 600w", "/static/cfe722fc9a62366072b260e97cd6b0af/39c09/sim-rolling.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The tracking chart shows how between 1976 and 1999, the strategy was continually underperforming its benchmark, while in more recent history its performance was about on par with buy and hold. However, these results must be taken with a grain of salt: Because the strategy makes only two trades a year, there is only very little data to lean upon, and we might just have gotten lucky.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3bd7c44c2e3f24c989d05ab1572c5f8b/39c09/sim-monte-carlo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACgUlEQVQoz42SO2gUYRDHp1LEwkIUxEKsLMTGSgsLIShWNop5aHwGNCJoIhIJomghKhqxiVhoocQHEaIQISAEQUHEInfJXS6amMRccsnd7e49dr993O7+ZO8SEUXxP/yZ+WaGPzPDJ72fctR3T3L+2Qwj38uoko5mpCkW59D1NJqRYSGfJp+bJ5/LsrAwz1wmg6br2I6Dsm2U42CaJp7rIW1PZ5D9caR5hJWnR6nrmqGzT+PRxwL9yTLvJy3iGZuJgiLrOFihR6VqHh4OFRxCPJRrYugG0vlyFjmcYEVrEjmRQJqHkYNxpGkYOTrC8pNJVp1Jsa59jI0dX9nUOc7mS+NsvfqNHTem2HN3mro733n4bh7XLCAXXqSRxmGkJVETjNgSiSeRIwnk4AjSMIwciCMHhpD6IaRhCGmMIU0x5FAM2TfEmSdT4JaQa68zLDs1ytq2MVafTbGmbYy17SnWdyTYdvMLe+9/49jjac71znClf5a7g/M8+JCl53OevrjGm6TOQKpAaq6IY5nIvbdzbL8+ye6uaXZ1TbPz1hQXX6XRlYfmKBwswAFcoPIbvUX6KKeMUjbS8mQcqY/XVj2eQPbHuD2gESHnmnhBgOsHOBUfu+Lj+H/SCwPKlkU+l0daeyaQhnhNrCnOueeZqpheMbEDtxoT8ncs1mxlY+g60hfLsKHjK1suT9A9qFeLJd+i7Nu1/jD8J5eglKpNWAkUumtj+9FNAoq+hbU02X+gbFfIZDWKhQK6piOWpSioIpplkC0ZlKwyTvT7lcKyLAzDqDZGPupdykeM4pJZJpfLUSqW8H0f+Tl6uHiPMCQIg2ouCIKagGlWffQOFvNL/HXtCD8ASxE2CVF1gigAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sim monte carlo",
            "title": "sim monte carlo",
            "src": "/static/3bd7c44c2e3f24c989d05ab1572c5f8b/39c09/sim-monte-carlo.png",
            "srcSet": ["/static/3bd7c44c2e3f24c989d05ab1572c5f8b/5a46d/sim-monte-carlo.png 300w", "/static/3bd7c44c2e3f24c989d05ab1572c5f8b/0a47e/sim-monte-carlo.png 600w", "/static/3bd7c44c2e3f24c989d05ab1572c5f8b/39c09/sim-monte-carlo.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Monte Carlo simulations can expand these horizons by exploring what might happen moving forward. This simulation shows a sobering result: At the 5th percentile, we notice almost no change. However, at the 95th percentile, we miss out on potential upside. I hope you don't mind that we won't be tracking this strategy on the site.`}</p>
    <p>{`This simple example shows how the analysis of an investment strategy is a multi-dimensional problem. We should always ask ourselves if the underlying idea makes sense, pay close attention to the various charts, and be aware that metrics, especially CAGR and maximum drawdown can be deceiving. Therefore, we encourage all TuringTrader members to learn about our `}<a parentName="p" {...{
        "href": "/help/charts-metrics/"
      }}>{`charts & metrics`}</a>{`, our `}<a parentName="p" {...{
        "href": "/articles/backtesting/"
      }}>{`backtesting assumptions`}</a>{`, and the `}<a parentName="p" {...{
        "href": "/articles/portfolio-wizard/"
      }}>{`mechanics of our portfolio wizard`}</a>{`.`}</p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      